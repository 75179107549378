import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../layouts'
import Feature from '../components/Feature'
import Sidebar from '../components/Sidebar'

const meta = {
  title: 'Fixed Wire Testing Services',
  url: 'https://www.maxpowerlimited.com/fixed-wire-testing/',
}

const FixedWireTestingPage = props => (
  <Layout {...props}>
    <Helmet
      title={meta.title}
      meta={[
        { itemprop: 'name', content: meta.title },
        { property: 'og:title', content: meta.title },
        { property: 'og:url', content: meta.url },
        { name: 'twitter:title', content: meta.title },
        { name: 'twitter:url', content: meta.url },
      ]}
      link={[{ rel: 'canonical', href: meta.url }]}
    />

    <Feature title={meta.title} />

    <div className="page">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-lg-8">
            <h2>What is Fixed Wire Testing?</h2>
            <p>
              Fixed Wire Testing is the report and inspection of electrical
              systems in a premises in regards to safety. It is known as the
              Electrical Installation Condition Report (EICR), previously known
              as the Periodic Inspection Report. It is designed to make sure
              that the electrical wiring is compliant with current regulations.
            </p>

            <h2 className="top-margin">How does the service work?</h2>
            <p>
              An EICR report works by testing the existing state of the
              electrical wiring, circuits and anything connected to the
              electrical system throughout the premises.
            </p>

            <p>
              This is a thorough inspection, and everything electrical will be
              tested for wear and tear, as well as more obvious visual damage.
              Our engineers and operatives are experts in spotting any potential
              hazards within your internal system. We check all areas of your
              system including the main incoming supply point through to
              sockets, light fittings and other wiring accessories by injecting
              test voltages. This shows the true functionality of cables and
              connections.
            </p>

            <p>
              Once the EICR is complete, we will pass on a full report with
              every item tested listed. If there is a fault with anything, then
              it will be categorised as either a C1, C2, or a C3. A C1 failure
              is in need of immediate action since there is a real danger
              present. A C2 requires attention in the near future but is not
              immediate, and a C3 is a suggested improvement.
            </p>

            <h2 className="top-margin">Is an EICR a legal requirement?</h2>
            <p>
              Yes, all fixed electrical wiring deteriorates with time, meaning
              that you need to regularly test your entire electrical
              distribution system to ensure its full safety and compliance, not
              only to stay within current regulations, but to keep your staff
              and visitors safe. The Fixed Wire Testing specialists that we work
              with are UKAS accredited, and make sure all work carried out
              complies with both the Health and Safety at Work Act 1974 and the
              Electricity at Work Regulations 1989 as well as IEE Wiring
              Regulation BS 7671. The Electricity at Work Regulations 1989 state
              that any electrical equipment that has the potential to cause
              injury or harm must be maintained and kept in a safe condition.
            </p>

            <h2 className="top-margin">How long does an EICR remain valid?</h2>
            <p>
              It depends on what your business is, it is usually between 1-5
              years with some exceptions. The EICR validity times are as follows
              for several business types:
            </p>

            <ul>
              <li>
                <strong>Commercial/Retail</strong> – 5 years
              </li>
              <li>
                <strong>Industrial</strong> – 3 years
              </li>
              <li>
                <strong>Hospitals with Theatre</strong> – 1 year
              </li>
              <li>
                <strong>Leisure</strong> – 3 years
              </li>
              <li>
                <strong>Construction</strong> – 3 months
              </li>
              <li>
                <strong>Education</strong> – 5 years
              </li>
              <li>
                <strong>Cinemas</strong> – 1 to 3 years
              </li>
              <li>
                <strong>Theatres</strong> – 3 years
              </li>
            </ul>
          </div>
          <div className="d-none d-lg-block col-lg-3 offset-lg-1">
            <Sidebar title={meta.title} />
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default FixedWireTestingPage
